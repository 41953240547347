<template>
  <div id="container" class="gnb_pdt">
    <div class="title_wrap"><h1>섹터관리</h1></div>
    <common-area-search
        :row="3"
        :colspan="2"
        @search="commonOnChangeEvent"
        @clear="cleanSearchDataEvent"
    >
      <template v-slot:R1_C1>
        <label>회사명</label>
        <ul>
          <common-multi-drop-down
              ref="cmpyCdRef"
              :params="{ searchCmpyGbnGrup : [Constant.efs.cmpyGbn.CLIENT_COMPANY] }"
              :data-columns="[{ field: 'cmpyNm', title: '회사명', width: '60%' },{ field: 'cmpyCd', title: '회사코드', width: '40%' }]"
              :url="`${ApiConfig.efsDomain}/efs/drop-down/company`"
              :data-value-field="'cmpyCd'"
              :data-text-field="'cmpyNm'"
              @change="value=>{
                vm.propsCmpyCd = value
                vm.propsLocaCd = ''
                vm.propsWhCd = ''
                vm.$refs.locaCdRef.widget().value('')
                vm.$refs.whCdRef.widget().value('')
                vm.$refs.sectCdRef.widget().value('')
                vm.commonOnChangeEvent()
              }"
          >
          </common-multi-drop-down>
        </ul>
      </template>
      <template v-slot:R1_C2>
        <label>로케이션명 </label>
        <ul>
          <common-multi-drop-down
              ref="locaCdRef"
              @change="value=>{
                vm.propsLocaCd = value
                vm.propsWhCd = ''
                vm.$refs.whCdRef.widget().value('')
                vm.$refs.sectCdRef.widget().value('')
                vm.commonOnChangeEvent()
              }"
              :disabled="!propsCmpyCd"
              :params="{ searchCmpyCd : propsCmpyCd }"
              :url="`${ApiConfig.efsDomain}/efs/drop-down/location`"
              :data-value-field="'locaCd'"
              :data-text-field="'locaNm'"
          >
          </common-multi-drop-down>
        </ul>
      </template>
      <template v-slot:R2_C1>
        <label>구역명 </label>
        <ul>
          <common-multi-drop-down
              ref="whCdRef"
              @change="value=>{
                vm.propsWhCd = value
                vm.$refs.sectCdRef.widget().value('')
                vm.commonOnChangeEvent()
              }"
              :disabled="!propsLocaCd"
              :params="{ searchCmpyCd : propsCmpyCd, searchLocaCd : propsLocaCd}"
              :url="`${ApiConfig.efsDomain}/efs/drop-down/wh`"
              :data-value-field="'whCd'"
              :data-text-field="'whNm'"
              :all-params-validator="true"
          >
          </common-multi-drop-down>
        </ul>
      </template>
      <template v-slot:R2_C2>
        <label>섹터명 </label>
        <ul>
          <common-multi-drop-down
              ref="sectCdRef"
              @change="commonOnChangeEvent"
              :disabled="!propsWhCd"
              :params="{ searchCmpyCd : propsCmpyCd, searchLocaCd : propsLocaCd, searchWhCd : propsWhCd}"
              :url="`${ApiConfig.efsDomain}/efs/drop-down/sect`"
              :data-value-field="'sectCd'"
              :data-text-field="'sectNm'"
              :all-params-validator="true"
          >
          </common-multi-drop-down>
        </ul>
      </template>
      <template v-slot:R3_C1>
        <label>사용여부</label>
        <ul>
          <common-button-group
              ref="activeYnRef"
              :style="'width: 100%; border: 0;'"
              :data-items="[{text: '전체', value:''},{text: '활성화', value:'Y'},{text: '비활성화', value:'N'}]"
              @click="commonOnChangeEvent"
          >
          </common-button-group>
        </ul>
      </template>

    </common-area-search>
    <!-- //검색 영역 -->

    <section>
      <div class="contents_top_sec">
        <div class="c_t_button_wrap fl">
          <button @click="$refs.manageWhWindowRef.kendoWidget().center().open()" class="mid_btn orange mr5 ">구역관리</button>
          <button @click="$router.push({ name: 'SectorManageNew' })" class="mid_btn orange">섹터등록</button>
        </div>
        <div class="c_t_button_wrap fr">
          <button class="mid_btn" @click="onClickExcelDownload"><i class="download_icon"></i>엑셀 다운로드</button>
        </div>
      </div>

      <div class="content_list">
        <!-- Kendo 영역 -->
        <kendo-grid ref="gridRef"
                    style="cursor: pointer"
                    :data-source="computedSectorGridRows"
                    :navigatable="false"
                    :sortable-mode="'multiple'"
                    :pageable-page-sizes="[5, 10, 20, 50, 100, 500, 1000]"
                    :reorderable="true"
                    :column-menu="false"
                    :resizable="true"
                    :selectable="'row'"
                    :columns="gridColumns"
                    :noRecords="{ template: '<div class=\'gridNoData\' style=\'text-align: center;\'>표시할 데이터가 없습니다.</div>' }"
                    @change="onChangeGrid"
        />
        <!-- Kendo 영역 끝 -->
      </div>
    </section>
    <kendo-window
        ref="manageWhWindowRef"
        :title="'구역관리'"
        :modal="true"
        :width="'1200px'"
        style="display:none; min-height: 400px;"
        @open="e=>{this.isWindowOpen = true}"
        @close="e=>{this.isWindowOpen = false}"
    >
      <component :is="isWindowOpen ? 'ManageWareHouse' : null"></component>
    </kendo-window>
  </div>
</template>

<script>
import EtnersCommonUtil from '@ebmp-fe-common-module/module/src/common/etners.common.util'
import EtnersDateTimeUtil from '@ebmp-fe-common-module/module/src/common/etners.datetime.util'
import EtnersEfsUtil from '@/common/etners.efs.util'
import ApiConfig from '@/api/api.config'
import ApiUtil from '@/api/api.util'
import { saveExcel } from '@progress/kendo-vue-excel-export'
import ManageWareHouse from '@/views/manage/sect/ManageWareHouse.vue'
import Constant from '@/common/constant'

export default {
  name: 'SectorManageList',
  components : {
    ManageWareHouse
  },
  computed: {
    computedSectorGridRows: function () {
      const vm = this

      const gridDataSource = new kendo.data.DataSource({
        name: 'gridDataSource',
        page: 1,
        pageSize: 10,
        serverPaging: true,
        serverFiltering: true,
        serverSorting: true,
        transport: {
          read: {
            url: `${ApiConfig.efsDomain}/efs/manage/sect`,
            type: 'GET',
            contentType: 'application/json',
            cache: false,
          },
          parameterMap: function (options, operation) {

            if (operation === 'read') {

              let filters = vm.searchFilterParam()
              let sort = EtnersEfsUtil.convertJSONArrayToJSON('sort', options.sort)

              vm.lastRouteParams = {
                loginUserToken: vm.$store.state.loginStore.lutk,
                skip: options.skip,
                take: options.take,
                page: options.page,
                pageSize: options.pageSize,
                ...sort,
                ...filters
              }
              return vm.lastRouteParams
            }

            if (operation !== 'read' && options.models) {
              return { models: kendo.stringify(options.models) }
            }
          },
        },
        schema: {
          type: 'json',
          parse: function (response) {
            let changeResultData = response.resultData
            let rowNumber = (vm.$refs.gridRef.dataSource.page() - 1)
                * vm.$refs.gridRef.dataSource.pageSize()
            for (let i = 0; i < changeResultData?.length; i++) {
              let row = changeResultData[i]
              // row 번호 셋팅
              row.rowNumber = response.total - rowNumber++
            }
            response.resultData = changeResultData
            return response
          },
          total: function (response) {
            return response.total
          },
          data: function (response) {
            if (response.resultStatus.messageCode === '2000') {
              return response.resultData
            }else if(response.resultStatus.messageCode === '4154'){
              vm.$store.dispatch('LOGOUT').then(() => {
                kendo.alert(response.resultStatus.messageText).bind('close', function() {
                  window.location.href = `/login`
                })
              })
            }else {
              kendo.alert( response.resultStatus.messageText)
              return ''
            }
          },
        },

        error: function (e) {
          console.log('error event handler', e.errors[0])
        },
      })
      return gridDataSource
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if(from.name === 'SectorManageDetail'){
        vm.$refs.gridRef.kendoWidget()?.dataSource.read()
      }
    })
  },
  mounted () {},
  methods: {
    cleanSearchDataEvent: function (ev) {
      const refs = this.$refs

      /**
       * 회사, 로케이션, 구역, 섹터
       * */
      refs.cmpyCdRef.widget().value('')
      refs.locaCdRef.widget().value('')
      refs.whCdRef.widget().value('')
      refs.sectCdRef.widget().value('')
      this.propsCmpyCd = ''
      this.propsLocaCd = ''
      this.propsWhCd = ''
      this.commonOnChangeEvent()

    },
    searchFilterParam: function () {
      let searchFilterOptions = {}
      const refs = this.$refs

      //회사명
      const cmpyCd = refs.cmpyCdRef.widget().value()
      if (cmpyCd) {searchFilterOptions.searchCmpyCd = cmpyCd}

      //로케이션명
      const locaCd = refs.locaCdRef.widget().value()
      if (locaCd) {searchFilterOptions.searchLocaCd = locaCd}

      //구역명
      const whCd = refs.whCdRef.widget().value()
      if (whCd) {searchFilterOptions.searchWhCd = whCd}

      //섹터명
      const sectCd = refs.sectCdRef.widget().value()
      if (sectCd) {searchFilterOptions.searchSectCd = sectCd}

      //사용여부
      const activeYn = refs.activeYnRef.widget().value()
      if (activeYn) {searchFilterOptions.searchActiveYn = activeYn}

      return searchFilterOptions ?? undefined
    },
    onChangeGrid (ev) {
      let cellIdx = window.event.target.cellIndex
      let boolForg = window.event.target.className === 'forg' // 키워드 강조 span태그 클릭시에도 deatil로 이동
      if (!isNaN(cellIdx) || boolForg) {
        let selectedRow = ev.sender.select()
        let rowData = ev.sender.dataItem(selectedRow)
        this.$router.push(
            { name: 'SectorManageDetail',
              params: { cmpyCd : rowData.cmpyCd, locaCd : rowData.locaCd, whCd : rowData.whCd, sectCd : rowData.sectCd }
            })
      }
    },
    commonOnChangeEvent: function () {
      const gridRef = this.$refs.gridRef
      gridRef?.kendoWidget()?.dataSource?.query({
        sort: {},
        take: 10,
        page:  1,
        pageSize:  10,
      })
    },
    onClickExcelDownload: function(e) {

      const vm = this
      vm.lastRouteParams.requestType = 'EXPORT'
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/manage/sect`, vm.lastRouteParams).then(response => {

        if (response.data.resultStatus.messageCode === '2000') {
          let resultData = response.data.resultData
          if(EtnersCommonUtil.isNotEmpty(resultData) && resultData.length > 0) {

            let goodsListGridColumns = vm.gridColumns.reduce((acc, cur) => {
              if(cur.field !== 'rowNumber'){ acc.push({ ...cur, width : '120px' }) }
              return acc;
            }, []);

            saveExcel({
              data: resultData,
              fileName: '섹터목록_' + EtnersDateTimeUtil.dateToYYYYMMDD(new Date()) + '.xlsx',
              columns: goodsListGridColumns
            })
          } else {
            kendo.alert('다운로드 할 자료가 없습니다.')
          }
        } else {
          kendo.alert( response.data.resultStatus.messageText)
          return false
        }
      })
    },
  },
  data () {
    const headerAttributes = { style: 'font-weight: bold; text-align: center;' }
    const centerAttributes = { style: 'text-align: center;' }
    const rightAttributes = { style: 'text-align: right;' }
    const vm = this
    return {
      vm,
      Constant,
      ApiConfig : ApiConfig,
      lastRouteParams : {},
      isWindowOpen : false,
      propsCmpyCd : '',
      propsLocaCd : '',
      propsWhCd : '',
      gridColumns: [
        {
          field: 'rowNumber',
          title: 'No',
          width: '3%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes,
          sortable: false
        },
        {
          field: 'cmpyNm',
          title: '회사명',
          width: '15%',
          headerAttributes: headerAttributes,
        },
        {
          field: 'locaNm',
          title: '로케이션명',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'whNm',
          title: '구역명',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'sectNm',
          title: '섹터명',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'sectCd',
          title: '섹터코드',
          width: '5%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'sectLocation',
          title: '섹터위치',
          width: '12%',
          headerAttributes: headerAttributes,
          attributes : centerAttributes
        },
        {
          field: 'goodsExistYn',
          title: '재고등록',
          width: '4%',
          headerAttributes: headerAttributes,
          attributes : centerAttributes
        },
        {
          field: 'frstRgstDt',
          title: '등록일시',
          width: '8%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'lastMdfyDt',
          title: '최종수정일시',
          width: '8%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'activeYn',
          title: '사용여부',
          width: '5%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes,
          template: function (dataItem) {
            return dataItem.activeYn === 'Y' ? '활성화' : '비활성화'
          }
        }
      ],
    }
  },
}
</script>
